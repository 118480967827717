<!-- 自定义tabbar -->
<template>
    <el-popover
        placement="bottom"
        width="250"
        trigger="click"
        ref="popoverRef"
        popper-class="tabbar-container"
        @show="visible = true"
        @hide="visible = false"
    >
        <div class="show-tab" slot="reference">
            <div class="tab-left">
                <img :src="iconSelectSvg" alt="" class="right-icon" />
                <span>{{ handleThisName() }}</span>
            </div>
            <img
                :src="iconAllowHeadSvg"
                alt=""
                style="width: 10px"
                :style="{
                    transform: visible ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
            />
        </div>
        <div class="tab-line">
            <img
                    src="@/assets/img/menu/cirque.png"
                    width="120px"
                    style="position: absolute;top:0"
                />
            <div
                v-for="item in TAB_LIST"
                :key="item.value"
                class="tab-each-line"
                :class="[value === item.value ? 'tab-each-line-select' : '']"
                @click="handleChangeTab(item.value)"
            >
                <img
                    :src="iconSvg"
                    alt=""
                    width="18"
                    height="18"
                    style="margin-right: 10px"
                />
                <p>
                    {{ item.label }}
                </p>
            </div>
            <!-- <div
                class="tabbar"
                @click="handleChangeTab(item.value)"
                :class="[
                    value === item.value ? `tabbar-select-${value}` : '',
                    `tabbar-${item.value}`,
                    item.disabled ? 'disabled' : '',
                ]"
                v-for="item in TAB_LIST"
                :key="item.value"
            >
                <div
                    class="font"
                    :class="value === item.value ? 'font-select' : ''"
                >
                    <img
                        :src="iconSelectSvg"
                        alt=""
                       
                        class="right-icon"
                        style="margin-right: 10px"
                    />
                    <span class="total">{{ item.label }}</span>
                    <img
                        :src="iconSelectCheckSvg"
                        style="position: absolute; right: 10px"
                        v-if="value === item.value"
                        class="right-icon"
                    />
                </div>
            </div> -->
        </div>
    </el-popover>
</template>
<script>
import { getNameByCode } from "@/utils";
const TAB_LIST = [
    {
        value: "NB",
        label: "宁波",
        simple: "甬",
    },
    {
        value: "SH",
        label: "上海",
        simple: "沪",
    },
    {
        value: "QD",
        label: "青岛",
        simple: "青",
    },
    {
        value: "TJ",
        label: "天津",
        simple: "津",
    },
    {
        value: "XM",
        label: "厦门",
        simple: "厦",
    },
];
export default {
    props: {
        value: {
            type: String,
            default: "NB",
        },
    },
    data() {
        return {
            leftIcon: require("@/assets/img/tab/icon-1.png"),
            iconSvg: require("@/assets/img/tab/icon.svg"),
            iconSelectSvg: require("@/assets/img/tab/icon-select.svg"),
            iconAllowHeadSvg: require("@/assets/img/tab/arrowhead.svg"),
            iconSelectCheckSvg: require("@/assets/img/tab/select.svg"),
            TAB_LIST,
            curWindowWidth: window.innerWidth,
            visible: false,
        };
    },
    methods: {
        handleThisName() {
            return getNameByCode(this.value);
        },
        handleChangeTab(type) {
            // 自动失去焦点
            this.$refs.popoverRef.doClose();
            this.$emit("update", type);
        },
        handleResize() {
            this.curWindowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>
<style>
.tabbar-container {
    padding: 0;
    border-radius: 5px;
    padding-bottom: 5px;
    background-image: url("../../assets/img/menu/cirque.png");
    background: linear-gradient(180deg, #ffdfdf 0%, #ffffff 24%, #ffffff 100%);
}
</style>
<style scoped lang="stylus">
.show-tab {
    .tab-left{
        display: flex;
        align-items: center;
        span {
            margin-left: 10px;
        }
    }
}
.tab-line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative
}

.show-tab {
    font-size: 18px;
    font-weight: 900;
    width: 110px;
    color: #fff8e6 !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 90%;
    box-sizing: border-box;
    padding: 2px 4px;
    margin-top: 2px;
    position: relative;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    cursor: pointer;
}

.tab-each-line{
    color: #9EAABD ;
    font-size:16px;
    line-height:20px;
    display:flex;
    align-items:center;
    padding:0 15px;
    position:relative;
    z-index:9999;
    cursor:pointer;
    &:hover{
        background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
        color:#8B0505
    }
}

.tab-each-line-select{
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
    color:#8B0505
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.tabbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    width:170px;
    padding: 2px 4px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    // backdrop-filter: blur( 4px );
    // -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    opacity : 0.4;
    position: relative;
}

.tabbar:hover{
    transform: scale(1.03);
}

.tabbar:hover::before {
    animation: shine 1.5s ease-out infinite;
    animation-iteration-count: 1;
  }


.tabbar::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -50px;
    opacity: 0;
  }

.tabbar-NB{
    background: var(--RED-C11C20);
    &:hover {
        opacity: 1 !important;
        background-image: linear-gradient(
            var(--RED-C11C20),
            var(--RED-79031D),
            var(--RED-68020F)
        ) !important;
    }
}

.tabbar-select-NB {
    opacity: 1 !important;
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    ) !important;
    transform: scale(1.03);
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.tabbar-SH{
    background: var(--BLUE-3366FF);
    &:hover {
        opacity: 1 !important;
        background-image: linear-gradient(
            var(--BLUE-3366FF),
            var(--BLUE-5493f4),
            var(--BLUE-006699)
        ) !important;
    }
}

.tabbar-select-SH {
    opacity: 1 !important;
    background-image: linear-gradient(
        var(--BLUE-3366FF),
        var(--BLUE-5493f4),
        var(--BLUE-006699)
    ) !important;
    transform: scale(1.03);
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.tabbar-QD{
    background: var(--GREEN-006600)
    &:hover {
        opacity: 1 !important;
        background-image: linear-gradient(
            var(--GREEN-006600),
            var(--GREEN-336600),
            var(--GREEN-003300)
        );
    }


}

.tabbar-select-QD {
    opacity: 1 !important;
    background-image: linear-gradient(
        var(--GREEN-006600),
        var(--GREEN-336600),
        var(--GREEN-003300)
    ) !important;
    transform: scale(1.03);
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.tabbar-TJ{
    background: var(--YELLOW-FF9900);
    &:hover {
        opacity : 1 !important;
        background-image: linear-gradient(
            var(--YELLOW-FF9900),
            var(--YELLOW-CC9966),
            var(--YELLOW-CC9933)
        );
    }
}

.tabbar-select-TJ {
    opacity: 1 !important;
    background-image: linear-gradient(
        var(--YELLOW-FF9900),
        var(--YELLOW-CC9966),
        var(--YELLOW-CC9933)
    ) !important;
    transform: scale(1.03);
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.tabbar-XM{
    background: var(--PURPLE-330099);
    &:hover {
        opacity : 1 !important;
        background-image: linear-gradient(
            var(--PURPLE-330099),
            var(--PURPLE-330066),
            var(--PURPLE-000066)
        )
    }
}

.tabbar-select-XM {
    opacity: 1 !important;
    background-image: linear-gradient(
        var(--PURPLE-330099),
        var(--PURPLE-330066),
        var(--PURPLE-000066)
    ) !important;
    border-radius: 2px 2px 0 0;
    border-left: 5px solid #A91520;
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
}

.total {
    display: block;
}
.simple {
    display: none;
}

.font-select {
    color: #fff8e6 !important;
}

.font {
    color: #fff8e6 !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 900;
    margin-right: 5px;
    user-select: none;
    display: flex;
    align-items: center;
}

.left-icon {
    width: 35px;
    height: 35px;
    position: absolute;
    left: -10px;
    top: 5px;
    opacity: 0.5;
}

.right-icon {
    width: 18px;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
    background: #d3d3d3 !important;
    .total{
        color: #8a2222 !important;
    }
}

@keyframes shine {
    0% {
      left: -50px;
      opacity : 0;
    }

    20% {
      left: 50px;
      opacity : 0.2;
    }

    40% {
      left: 100px;
      opacity : 0.6;
    }

    60% {
      left: 100%;
      opacity : 0;
    }

    to {
      left: 100%;
      opacity :0;
    }
  }
</style>
