<template>
    <div style="width:100%;display: flex;justify-content: center;line-height: 0;">
        <div class="container" :class="handleThisClass()">
        <img :src="placeSvg" alt="" style="width: 16px; opacity: 0.7" />
        <div class="font">{{ handleThisName() }}</div>
    </div>
    </div>
</template>
<script>
import {getNameByCode} from "@/utils"
export default {
    name: "PlaceShow",
    props: {
        name: {
            type: String,
            default: 'NB',
        },
    },
    data() {
        return {
            placeSvg: require("@/assets/img/tab/place.svg"),
        };
    },
    methods: {
        handleThisClass() {
            return this.name.toLowerCase() + "-place";
        },
        handleThisName() {
            return getNameByCode(this.name);
        },
    },
};
</script>
<style scoped>
.container {
    width: 75px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    gap: 5px;
    padding: 2px 0;
    color: #e9f4ff;
}

.qd-place{
    background: #339933;
    box-shadow: inset 4px 4px 8px #009900,
            inset -4px -4px 8px #339900;
    border: 2px solid #66CC66;
}

.nb-place {
    background-color: #e5512d;
    border: 2px solid #f5bb8b;
    box-shadow: inset 4px 4px 8px #b03e23, inset -4px -4px 8px #ff6437;
}
.sh-place {
    border: 2px solid #9ecdfe;
    background: #2a76fd;
    box-shadow: inset 4px 4px 8px #205bc3, inset -4px -4px 8px #3491ff;
}

.tj-place{
    border: 2px solid #ffd53d;
    background: #c48806;
    box-shadow: inset 4px 4px 8px #a08806, inset -4px -4px 8px #caad14;
}

.xm-place{
    border: 2px solid #8a2be2;
    background: #4b0082;
    box-shadow: inset 4px 4px 8px #2e0854, inset -4px -4px 8px #6a287e;
}

.font {
    font-weight: 900;
    user-select: none;
}
</style>
